//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import link from '@/public/link.js';
import localStorage from '@/storage/localStorage';
import cookie from '@/storage/cookies.js'
import {AppConfig} from '@/config'
import userApi from '@/api/apiUser/apiUser'
export default {
  name: 'Userinfo',
  data () {
    return {
      isShow: true,
      areaBox: false,
      colorClass: '',
      data1: [],
      shake: false,
      pushUserinfo: true
    }
	},
  inject: ['reload'],
  components: {},
  methods: {
    toHelpCenter2(){
      this.$router.push({path:"/HelpCenter2"})
    },
    showArea () {
      if (this.data1.length === 0) {
        this.data1 = JSON.parse(localStorage.read('areaAll'))
      }
      this.areaBox = true
      this.shake = true
    },
    areaBoxHoverOut () {
      let that = this
      this.shake = false
      setTimeout(function () {
        if (!that.shake) {
          that.areaBox = false
        }
      }, 50)
    },
    selectArea (val, index) {
      this.colorClass = index
      this.data1.forEach(item => {
        item.checked = false
      })
      val.checked = true
      this.areaBox = false
      cookie.cookieSet('area', JSON.stringify(val))
      localStorage.set('areaAll', JSON.stringify(this.data1))
			this.reload() // 重载
    },
    quit () {
      this.$confirm('确认退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async res => {
          let { data } = await userApi.sendLogout()
          if (data && data.code === 0) {
            this.$store.commit('LOGIN_OUT')
            this.$router.push({path: '/index'})
            this.$message.success(data.msg)
          } else {
            this.$message.error('退出失败')
          }
        })
        .catch(() => {})
    },
    pushThis () {
      let that = this
      this.pushUserinfo = false
      this.$nextTick(function () {
        that.pushUserinfo = true
      })
    },
    loginIndex () {
      // link.login()
      let data = {
        callBack: function () {
          this.$store.dispatch('setUser')
          // this.pushThis()
          this.$router.push({path: '/index'})
        }.bind(this)
      }
      this.$_login(data)
    },
    tosign () {
      link.sign()
    },
    toIndex () {
      this.$router.push({path: '/index'})
    },
    toPersonal () {
      if (this.$store.state.loginType) {
        
        link.personal()
      } else {
        this.loginIndex()
      }
    },
    toPage (e) {
      if (this.$store.state.loginType) {
        let target = e.target || e.srcElement;
        target.dataset.link && link[target.dataset.link]()
      } else {
        this.loginIndex()
      }
    },
    toMyMessage () {
      link.myMessage()
    },
    toCompanyManage () {
      link.companyManage()
    }
  },
  watch: {},
  computed: {
    monitorId () {
      let area = cookie.cookieRead('area')
      let res = JSON.parse(area)
      return res
    }
  },
  mounted () {},
  created () {
    if (this.$store.state.loginType) {
      this.isShow = false
    }
  }
}
