//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiHelp from '@/api/apiHelp'
export default {
  name: 'Bottom',
  data () {
    return {
      quickLinkList:[]
    }
  },
  methods: {
    route_push(parm){
      switch(parm){
        case 'zhuce':
          this.$router.push({path:'/howsign'})
          break
        case 'zizhi':
          this.$router.push({path:'/qualification'})
          break
        case 'goumai':
          this.$router.push({path:'/howtobuy'})
          break
      }
    },
    async getList(){
      let res = await apiHelp.getQuickLink()
      // console.log(res.data.data)
      // contentId: 59
      // createTime: 1565174437693
      // id: 1
      // isDeleted: 0
      // linkPath: "https://dev.gcw.net/#/content?id=59"
      // name: "公司介绍"
      // sortOrder: 1
      // updateTime: 1565174437693
      this.quickLinkList = res.data.data
    },
    toHelpCenter(query){
      this.$router.push({path:'/content',query:{id:query}})
    },
    //  quickLinkList[0].contentId
  },
  computed:{

  },
  created(){
    // $router.push({path:'/aboutwe'})
    this.getList()
  }
}
