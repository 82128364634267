//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {api} from '@/api/apiBusiness'
import {isvalidPhone} from '@/components/form/validate'
export default {
  name: "release",
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$refs.form.resetFields()
        this.$emit("colse", false);
      }
    },
    user: function(){
      return this.$store.state.user
    }
  },
  data() {
    return {
      locationArr: [],
      props1: {
        label: 'name',
        value: 'no'
      },
      form: {
        goods: [{ name : "", spec : "", brand : "", unit : "", num : "" }],
        province: '',
        city: '',
        district: '',
        status: 1,
        location: [],
        concat: '',
        concatNum: '',
        needsTime: '',
        addressDetail: '',
        qualityRequire: '',
        useage: ''
      },
      rules: {
        concat: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        concatNum: [
          { required: true, trigger: 'blur', validator: this.validPhone }
        ],
        addressDetail: [
          { max: 60, message: '长度不能超过60个文字', trigger: 'blur' }
        ],
        qualityRequire: [
          { max: 60, message: '长度不能超过60个文字', trigger: 'blur' }
        ],
        useage: [
          { max: 60, message: '长度不能超过60个文字', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '到货地方不能为空', trigger: 'change' }
        ],
        addressDetail: [
          {required: true, message: '详细地址不能为空', trigger: 'change' }
        ]
      },
      loading: false,
      time: 120,
      timer: null
    };
  },
  methods: {
    addProduct() {
      this.form.goods.push({
        name: "",
        spec: "",
        brand: "",
        unit: "",
        num: ""
      });
    },
    validPhone(rule, value,callback){
      if (!value){
          callback(new Error('请输入电话号码'))
      }else  if (!isvalidPhone(value)){
          callback(new Error('手机号码格式错误，请检查'))
      }else {
          callback()
      }
    },
    removeProduct(index) {
      if (this.form.goods.length == 1) return;
      this.form.goods.splice(index,1);
    },
    getTelCode(){
      let _this = this;
      this.loading = true
      this.timer = setInterval(function(){
        if(_this.time == 0){
          clearInterval(this.timer)
          _this.loading = false
        }
        _this.time--
      },1000)

    },
    getNow(){

    },
    // 初始化地区
    async initAre () {
      let { data } = await api.getArea()
      this.locationArr = data.data.data
    },
    async addDemand(){
      this.$refs.form.validate(async (valid) => {
        if(valid){
          let isTrue = true;
          let pattern = /^[0-9]+\.{0,1}[0-9]{0,2}$/
          for(let item = 0; item < this.form.goods.lengthl; item++){
            if(item.name == '' || item.brand == '' || item.num == '' || item.spec == '' || item.unit == ''){
              this.$message.error('请补全商品信息');
              isTrue = false;
              break;
            }
            if(item.num > 9999999.99){
              this.$message.error('数量最多可输入9999999.99');
              isTrue = false;
              break;
            }
            if(!pattern.test(item.num)){
              this.$message.error('数量格式不正确');
              isTrue = false;
              break;
            }
          }
          if(!isTrue) return
          this.form.province = this.form.location[0] || ''
          this.form.city = this.form.location[1] || ''
          this.form.district = this.form.location[2] || ''
          let {data} = await api.addNeeds(this.form);
          if(data.code == 200){
            this.$message.success(data.msg);
            this.$refs.form.resetFields()
            this.$emit("colse", false);
          }else{
            this.$message.error(data.msg);
          }
        }
      })
    },
    changeNumber(val,index){
      if(val > 9999999.99){
        let str = val+''
        this.form.goods[index].num = str.substring(0,val.length-1)
      }else if(val.indexOf('.') > -1){
        let num = val.indexOf('.') + 3
        this.form.goods[index].num = val.substring(0,num)
      }
    }
  },
  created() {
    this.form.concat = this.user.realName;
    this.form.concatNum = this.user.telPhone;
    this.initAre()
  }
};
