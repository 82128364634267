//
//
//
//
//
//

import { AppConfig } from "@/config";
import cookie from "@/storage/cookies";
import localStorage from "@/storage/localStorage";

export default {
  name: "Header",
  data() {
    return {
      waitShow: false,
      areaBox: false,
      colorClass: "",
      data1: [],
      getProvince: {},
      isShow: true,
      url: {
        procurement: "@/../static/caigou.json" // 最新采购
      }
    };
  },
  props: ["flag", "searchs"],
  components: {
    Search: () => import("@/components/header/components/Search.vue"),
    Userinfo: () => import("@/components/header/components/Userinfo.vue"),
	},
	inject:['reload'],
  methods: {
    search() {
      this.$emit("search",true);
    },
    quickGoods(){
      this.$refs.search.quickGoods()
    }
  },
  mounted() {
  },
  computed: {},
  created() {}
};
