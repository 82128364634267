//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isMoreHe: false,
      sHeight: 0,
      currentId: ""
    };
  },
  props: {
    tabList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isBorder: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  created() {},
  methods: {
    paramsChange(item) {
      this.currentId = item.value;
      this.$emit("currentItem", item);
    },
    setIsMore() {
      this.$nextTick(() => {
        let sHeight = this.$refs["search-item"].clientHeight;
        this.sHeight = sHeight;
        this.isMoreHe = sHeight > 50;
      });
    }
  },
  mounted() {
    this.currentId = this.tabList.length ? this.tabList[0].value : "";
    this.setIsMore();
  },
  updated() {}
};
