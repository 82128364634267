//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 无输入关键字
import { AppConfig } from "@/config";
import { apiHome } from "@/api/apiHome";
import localStorage from "@/storage/localStorage";
import { apiCart } from '@/api/apiCart'
import Release from '@/components/header/components/release.vue'
import env from '../../../../env'
export default {
  name: 'Search',
  mixins: [],
  extends: {},
  props: ['flag','searchs'],
  data () {
    return {
	    showClass:false,
        initPlaceholder:'无输入关键字',
        placeholder:'请输入商品名称',
        query: null,
        searchList: [],
        // num: '',
        searchStyleList: [],
        hotKey:[],
        showRecommend:false,
        recentlySearchList:[],// 最近搜索记录
        hoverIndex:null,
        tabType: true,
        type: 1,
        isShow: false
    }
  },
  computed: {},
  components: {
      QuickGoods: () => import('@/components/header/components/QuickGoods.vue'),
      Release
  },
  filter: {},
  watch: {},
  methods: {
    spliceSearchList(item){
      let  string = item
      let num = this.query.length
      if(this.query == string.substring(0,this.query.length)){
        string = this.query+ '<span style="font-weight: bold;">'+string.substring(num,item.length) +'</span>'
      }
      // console.log(string)
      return string
    },
    input() {
      if (this.type == 2) {
        this.showRecommend = false;
        return;
      }
      if(this.query == ''){
        this.showRecommend = true
      }else{
        this.showRecommend = false
      }
      if(this.query.length>50){
        this.query = this.query.slice(0, 50)
      }
    },
    focus (){
      // 获取焦点
      if (this.type == 2) {
        this.showRecommend = false;
        return;
      }
      this.showClass = true
      if(this.query == ''){ // 搜索词为空  打开推荐词
      // this.query = this.placeholder
        // this.placeholder = this.initPlaceholder
        this.showRecommend = true
      }else{
        this.showRecommend = false
      }
    },
    onBlur(){
      if (this.type == 2) {
        this.showRecommend = false;
        return;
      }
      let  that = this
	    that.showClass = false
      setTimeout(
        function(){
          that.searchList = []
          that.showRecommend = false
       }, 150);
    },
    getSearchList(){
      this.recentlySearchList = []
      let List = localStorage.read('searchList')
      if(List == null){
        List = []
      }else{
        List = JSON.parse(List)
      }
      this.recentlySearchList = List
    },
    deletedSearchList(){
      localStorage.clear('searchList')
      this.getSearchList()
    },
    setSearchList(){
      // 校验是否为空
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      let bol =  re.test(this.query);
      if(this.query == ''|| bol){
        return
      }
      // 如果有历史搜索  则提前
      let index = this.recentlySearchList.indexOf(this.query)
      if(index !== -1){
        this.recentlySearchList.splice(index,1)
      }
      this.recentlySearchList.unshift(this.query)
      // 删除多余元素  只保留十个
      while(this.recentlySearchList.length>10){
        this.recentlySearchList.pop()
      }
      let List = JSON.stringify(this.recentlySearchList)
      localStorage.set('searchList',List)
    },
    selectStyle(index) {
      this.hoverIndex = index
      this.changeStyle()
      this.searchStyleList.splice(index, 1, true);
    },
    async selectDown() {
      let key = window.event.keyCode
        switch(key){
          case 13 :
            this.search()
            return
            break
          case 38:
            this.monitorOnkeydown(38)
             return
            break
          case 40:
            this.monitorOnkeydown(40)
            return
            break
        }

        this.hoverIndex = null
        let data = await apiHome.searchPrompt(this.query);
        this.searchList = data.data.data;
        this.initSearchStyleList()
    },
    changeStyle() { // 初始化
      // hover清空
      for (let i = 0; i < this.searchStyleList.length; i++) {
        this.searchStyleList.splice(i, 1, false);
      }
    },
    initSearchStyleList(){ // 初始化搜索选择列表
      this.searchStyleList = []
      for(let i =0;i<this.searchList.length;i++){
        this.searchStyleList.push(false)
      }
    },
    async getHotKey(){ // 热门搜索词
      let res = await apiHome.getHotKey()
      this.hotKey = res.data
      if(this.query == '' && this.$route.path != '/shopList'){
        this.placeholder = this.hotKey[0]
      }
    },
    monitorOnkeydown(key){ // 设置键盘监听
      let num = 1
      let index
      switch(key){
        case 38:
          num *= -1
          break
        case 40:
          num *= 1
          break
          }
      if(this.hoverIndex == null){
        index = 0
        if(num == 1){
          this.selectStyle(0)
        }
        if(num == -1){
          this.selectStyle(this.searchStyleList.length - 1)
        }
        return
      }else{
        index = this.hoverIndex + num
      }
      let max = this.searchStyleList.length
      switch(index){
        case -1:
          index = this.searchStyleList.length - 1
          break
        case max:
          index = 0
          break
        default:
            break
      }
      this.query = this.searchList[index]
      this.selectStyle(index)
    },
    clickList(code) {
      // console.log(code)
      this.showRecommend = false
      this.query = code;
      this.searchList = [];
      this.search();
    },
    search() {
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      let bol =  re.test(this.query);

      if(this.query == ''|| bol){
        if(this.$props.searchs && this.$props.searchs != undefined){
          this.query = this.$props.searchs;
        }else{
          this.query = this.tabType && this.placeholder !='请输入商品名称' && this.placeholder != '请输入企业名称' ? this.placeholder : ''
        }
      }
      this.searchList = [];
      this.setSearchList()


      let ss = this.regx(this.query);
      if (ss) {
        localStorage.set("search_query", this.query);
        // let bolooen = this.query.indexOf('商砼')
        // if(bolooen!= -1){
        //   this.$router.push({path:'/talents'})
        //   return
        // }

        if (this.$route.path === "/engineeringSearch") {
          this.$emit("search");
          this.type ==  2 &&  this.$router.push({ path: `/shopList` })
        } else if(this.$route.path === "/shopList"){
          this.$emit("search");
          this.type ==  1 &&  this.$router.push({ path: `/engineeringSearch` })
        } else {
          this.type == 1 ? this.$router.push({ path: `/engineeringSearch` }) : this.$router.push({ path: `/shopList` });
        }
      } else {
        localStorage.clear("search_query");
        if (this.$route.path === "/engineeringSearch") {
          this.$emit("search");
          this.type ==  2 &&  this.$router.push({ path: `/shopList` })
        } else if(this.$route.path === "/shopList"){
          this.$emit("search");
          this.type ==  1 &&  this.$router.push({ path: `/engineeringSearch` })
        } else {
          this.type == 1 ? this.$router.push({ path: `/engineeringSearch` }) : this.$router.push({ path: `/shopList` });
        }
      }
    },
    regx(newName) {
      // var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
      //     regCn = /[·！#￥\s（——）：；“”‘、，|《。》？、【】[\]]/im;
      var regEn = /[`~!@$%^&*_+<>?:"{},\/;'[\]]/im,
        regCn = /[·！￥——：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(newName) || regCn.test(newName)) {
        return false;
      } else {
        return true;
      }
    },
    toindex() {
      this.$router.push({ path: "/index" });
    },
    loginAuth () {
      let data = {
        callBack: function () {
          this.$store.dispatch('setUser')
          // this.pushThis()
        }.bind(this)
      }
      this.$_login(data)
    },
    tocar() {
        if (!this.$store.state.loginType) {
          this.loginAuth()
        } else if (!this.$store.state.jurisdiction.addCar) {
          // console.log(this.$store.state.jurisdiction.addCar)
            window.location.href = env[process.env.environment].VUE_APP_PERSONURL+'/authenticationManagement'
        } else {
            // this.$router.push({ path: "/busone" });
          if(this.$route.path==='/busone'){
            return
          }
          let routeData = this.$router.resolve({ path: '/busone' });
          window.open(routeData.href, '_blank');
        }
    },

    quickGoods () { // 打开快速找货
        this.$refs.quickGoods.open()
    },
    tab(type) {
      if (type != this.type) {
        this.tabType = !this.tabType;
      }
      this.type = type;
      if (type == 2) {
        this.showRecommend = false;
      }
      if (this.type == 1) {
        this.placeholder = this.hotKey[0] || "请输入商品名称";
      } else {
        this.placeholder = "请输入企业名称";
      }
    },
    quickRelease(){
      if(this.$store.state.token){
        this.isShow = true;
      }else{
        let data = {
          callBack: function () {
            this.$store.dispatch('setUser')
          }.bind(this)
        }
        this.$_login(data)
      }
    }
  },
  created () {
    if (this.$store.state.loginType) {
      this.$store.dispatch('setShopCarNum')
    }
    this.getSearchList()
    this.getHotKey()
    if(this.$route.path == '/shopList'){
      this.type = 2;
      this.tabType = false;
      this.placeholder = "请输入企业名称";
    }
    if (this.$props.searchs == undefined) {
      this.query = "";
    } else {
      this.query = this.$props.searchs;
    }
  },
  mounted () {}
}
