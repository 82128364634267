//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localStorage from "@/storage/localStorage";
import { apiHome } from "@/api/apiHome";
import {isvalidPhone} from '@/components/form/validate'
import {Num} from '@/components/form/num'
export default {
  name: 'QuickGoods',
  mixins: [],
  extends: {},
  props: {},
  data () {
    return {
        dialogFormVisible: false,
        rules:{
            selectedOptions:[
                { required: true, trigger: 'change', validator: this.validxuqiuType }
            ],
            desc:[
                { required: true, trigger: 'blur', validator: this.validDescribe }
            ],
            tel:[
                { required: true, trigger: 'blur', validator: this.validPhone }
            ],
            vertifyCode: [
                {required: true, trigger: 'blur', validator: this.validCode}
            ]
        },
        labelPosition: 'left',
        errorMsg: false,
        errorMessage: '',
        loading: false,
        time: 60,
        getNow: '立即获取',
        isSend: true, // 判断是否发送过验证码  true为未发送过
        options: [],
        need: {
            cid1: null,
            cidname1: null,
            desc: null,
            status: 0,
            tel: null
        },
        isshow: false,//提交成功弹框
        isfail:false,//提交失败弹框
        form: {
            tel: '',
            desc: '',
            selectedOptions:null,
            vertifyCode: ''
        },
    }
  },
  computed: {},
  components: {

  },
  filter: {},
  watch: {
    dialogFormVisible:{
      handler(){
        if(this.dialogFormVisible == false){
          this.$refs.form.resetFields()
        }else{
          this.getDataOpt()
        }
      },
      deep:true
    }
  },
  methods: {
    validxuqiuType(rule, value,callback){
        if(!value && !(value===0)){
            callback(new Error('请选择商品分类'))
        }else{
            callback()
        }
    },
     validCode (rule, value, callback) { // 验证码
      if (!value) {
        callback(new Error('请输入验证码'))
      } else if (!Num(value)) {
        callback(new Error('验证码输入有误'))
      } else if (!(value.length === 6)) {
        callback(new Error('验证码输入有误'))
      } else {
        callback()
      }
    },
    validPhone(rule, value,callback){
        if (!value){
            callback(new Error('请输入电话号码'))
        }else  if (!isvalidPhone(value)){
            callback(new Error('请输入正确的11位手机号码'))
        }else {
            callback()
        }
    },
    validDescribe(rule, value,callback){
        if (!value){
            callback(new Error('请输入需求描述'))
        }else if(value.length>500){
            callback(new Error('需求描述不能超过500字'))
        }else{
            callback()
        }
    },
    handleChange (item) {
        for(let i = 0;i<this.options.length;i++){
            if(this.options[i].id === item){
                this.need.cid1 =  this.options[i].id
                this.need.cidname1 =  this.options[i].name
            }
        }
    },
     dosubmit () {
        this.$refs.form.validate((valid) => {
            if (valid) {
            this.axios_changeitemNum()
            }
        });
    },
    async axios_changeitemNum() { // 委托找货
        this.need.desc =  this.form.desc
        this.need.tel = this.form.tel
        let result = await apiHome.postDemand(
            this.need.cid1,
            this.need.cidname1,
            this.form.desc,
            this.form.tel,
            this.form.vertifyCode
        )
        if(result.code == 0){
            this.dialogFormVisible = false
            this.$message.success('提交成功！')
            this.ishow(false, 'success') //可以提交
            this.$refs.form.resetFields()
        }else{
          this.$message.error('请输入正确的验证码！')
        }
    },
    ishow (bol,issuccess) {
        if(issuccess){
            this.isshow = !this.isshow
        }else{
            this.isfail = !this.isfail
        }
        if(bol){
            this.numberValidateForm_new()
            this.isfail = false
            this.isshow = false
        }
    },
    numberValidateForm_new(){
        this.form = {
            tel: '',
            selectedOptions: null,
            desc: '',
            vertifyCode: ''
        }
    },
    async getDataOpt () {
        let TypeList = await apiHome.getCatList()
        this.options = TypeList.data
        this.options.push({name:'其它', id:0})
    },
    getTelCode () {
        let that = this
        if (this.form.tel == "") {
          this.errorMessage = '请输入手机号！'
          this.errorMsg = true
          setTimeout(() => {
            this.errorMsg = false
          }, 2000);
          return false
        } else {
          var Tel = /^[1][3,4,5,7,8,9][0-9]{9}$/
          if (!Tel.test(this.form.tel)) {
            this.$message({
              message: '请输入正确的手机号',
              type: 'warning',
              duration: 3000
            })
          } else {
            this.$axios.get('/api/demand/vertifyCode?telphone=' + this.form.tel).then((res) => {
              if (res.data.code === 0) {
                that.loading = true;
                let time = new Date()
                  localStorage.set('isSignSend'+this.form.tel, Number(time))
                that.$message({
                  message: "验证码已发送到您的手机，请查收!",
                  type: "success",
                  duration: 3000
                })
                var timeOut = setInterval(() => {
                  that.time = that.time - 1;
                  if (that.time === 0) {
                    clearInterval(timeOut)
                    that.loading = false;
                    that.time = 60;
                  }
                }, 1000)
              } else {
                that.$message({
                  message: res.data.message,
                  type: 'warning',
                  duration: 3000
                })
              }
            })
          }
        }
      },
    open(){
        this.dialogFormVisible = true
    },
    close(){
      this.dialogFormVisible = false
    },
    init(){
        this.numberValidateForm_new()
    }
  },
  created () {
      this.init()
    // this.$message.success(res.data.msg)
    // this.$message.error(res.data.msg)
  },
  mounted () {}
}
