import { render, staticRenderFns } from "./Bottom.vue?vue&type=template&id=caee32d2&scoped=true&"
import script from "./Bottom.vue?vue&type=script&lang=js&"
export * from "./Bottom.vue?vue&type=script&lang=js&"
import style0 from "./Bottom.vue?vue&type=style&index=0&id=caee32d2&lang=scss&rel=stylesheet%2Fscss&scoped=true&"
import style1 from "./Bottom.vue?vue&type=style&index=1&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caee32d2",
  null
  
)

export default component.exports